import React from "react"
import Slider from 'react-slick' 
import './OurSpecials.scss'
import { Link } from "gatsby";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";

class OurSpecials extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            slides: []
        }

        this.slider = React.createRef()
    }

    async componentDidMount() {
        await this.filterSlides(this.props.data.specials)
    }

    filterSlides(slides) {
        return new Promise((resolve, reject) => {
            try {
                const today = new Date(Date.now()).getTime()
                let currentSlides = []
    
                slides.forEach(slide => {
                    const expiration = new Date(slide.expiration).getTime()
                    const startDate = new Date(slide.startDate).getTime()
    
                    if (startDate <= today && today < expiration) currentSlides.push(slide) // pushed slide into new array if today fits between expiration and start date
                })
    
                this.setState({
                    slides: currentSlides
                })
    
                resolve(currentSlides)    
            }

            catch (err) {
                console.log(err)

                reject(err)
            }
        })
    }

    next() {
        this.slider.slickNext()
    }

    prev() {
        this.slider.slickPrev()
    }

    render() {
        if (this.state.slides.length === 0) return <div></div>

        let banners = this.state.slides.map((special, i) => {
            return (
                <div key={i} style="padding: 10px; position: relative;">
                    <a href={special.bannerLink} className='our-bannerCont'>
                        <img alt={special.bannerText != '' ? special.bannerText : ''} className='our-image' src={special.bannerImage} />
                    </a>
                    <span className="our-image-banner">
                    <h3>{special.bannerText != '' ? special.bannerText : ''}</h3>
                    
                        <Link style={{color: special.buttonColor}} to={special.bannerLink != '' ? special.bannerLink : '/'}>{special.buttonText != '' ? special.buttonText : ''}</Link>
                    </span>
                </div>
            )
        })

        const settings = {
            infinite: true,
            arrows: false,
            slidesToShow: Math.min(this.state.slides.length, 4),
            slidesToScroll: 1,
            dots: true,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: { 
                    slidesToShow: Math.min(this.state.slides.length, 3),
                    slidesToScroll: Math.min(this.state.slides.length, 1),
                  }
                },
                {
                  breakpoint: 700,
                  settings: { 
                    slidesToShow: Math.min(this.state.slides.length, 2),
                    slidesToScroll: Math.min(this.state.slides.length, 1),
                  }
                },
                {
                    breakpoint: 500,
                    settings: { 
                      slidesToShow: Math.min(this.state.slides.length, 1),
                      slidesToScroll: Math.min(this.state.slides.length, 1),
                    }
                  }
              ]
        }

        return (
            <div className='our-parent specials'>
                <div className="our-Cont">
                    <div className='our-specials'>
                        <div className='our-our'>Our</div>
                        <div>Specials</div>
                    </div>
                    <div className="our-specials nav-buttons" style={this.state.slides.length <= 1 ? { display: 'none' } : { display: 'flex' }}>
                        <div className='our-button' onClick={() => this.prev()}>
                        <span className="specials-arrow-icon left"></span>
                        </div>
                        <div className='our-button' onClick={() => this.next()}>
                            <span className="specials-arrow-icon right"></span>
                        </div>
                    </div>
                </div>
                <div className='our-slider'>
                    <Slider ref={slider => this.slider = slider} {...settings}>
                        {banners}
                    </Slider>
                </div>
            </div>
        )
    }
}

export default OurSpecials